import PARTSLOADER from '../plugins/partsloader'
import CONTENT_TITLE from '../plugins/contentTitle'

// スクロールして画面エリアに入ったら要素を表示する lazydisp
import LAZYDISP from '../plugins/lazydisp'

// カレンダーカスタマイズ
import FULLCALENDAR from '../plugins/fullcalendar'

//pictureタグ使用時にIE11で画像サイズ指定
import IE_SRCSET from '../plugins/polyfill-ie-srcset'

// IE11でSVGの外部参照を有効にする
import 'svgxuse'

const [d,mq] = [document,window.matchMedia('(max-width: 899px)')]

export default function INIT() {
  /*****
   * Promise
   */
  window.load = {
    'header': new $.Deferred, // PARTSLOADERの`name`と合わせる
    'footer': new $.Deferred, // PARTSLOADERの`name`と合わせる
    'mapScript': new $.Deferred, // googlemapAPIのURLパラメータでresolveします
    'loadHtml': new $.Deferred, // すべてのLoad Htmlが完了したらresolveする
  }

  //header.html, footer.html読み込み
  PARTSLOADER([
    {
      name: 'footer',
      loadhtml: '/asset/html/footer.html',
      target: 'footer',
      method:'replaceWith',
      callback(){
        $('footer [data-plugin="getCopyright"]').getCopyright()
      }
    },
    {
      name: 'header',
      loadhtml: '/asset/html/header.html',
      target: 'header',
      method:'replaceWith',
      callback(r,_){
        $('header .p-gnav').currentMenu({mode:1,default:999});
        $('header .p-gnav__subnav').currentMenu({mode:0,default:999});
        $('header .p-gnav__item').each( function(){
          const $indicator = $(`<button class="p-gnav__item__indicator">`)
          if( mq.matches && this.querySelectorAll('.p-gnav__subnav-list').length ) {
            $(this).append( $indicator )
          }
          $(this).find('.p-gnav__subnav').slideUp();
          $indicator.on('click', ()=>{
            $(this).find('.p-gnav__subnav').slideToggle();
            $indicator.toggleClass('is-active')
          });
        });
      }
    }
  ])
  // プライバシー・会社概要・サイトマップのコンテンツタイトル
  CONTENT_TITLE({
    'sitemap':'<span>SITEMAP</span><small>サイトマップ</small>',
    'privacy':'<span>PRIVACY POLICY</span><small>プライバシーポリシー</small>',
    'profile':'会社概要'
  })
  
  //polyfill-ie-srcset
  IE_SRCSET()

  // DOM Ready
  $(() => {
    // <body>にクラス付与
    $.bodyClass();
    // htmlデータ読み込み
    $('[data-load-html]').loadHtml();
    // カレントメニュー
    $('[data-plugin=currentMenu]').currentMenu({mode:0,default:999});
    // ページングテキスト
    $.pagingText({
      prev:'PREV',
      next:'NEXT',
      list:'LIST'
    })
    // ページトップリンクをスクロール量で表示するサンプル
    $.scrollspy({
      trigger:200,
      onBefore(/* pos */) {
        $('.p-pagetop__wrap').addClass('hidden');
      },
      onAfter() {
        $('.p-pagetop__wrap').removeClass('hidden');
      }
    })

    // lazydisp
    $.when( window.load['loadHtml'] ).then( () => LAZYDISP('[data-lazydisp]') )
  })

  $.when( window.load['footer'] ).then(() => {
    
    // fullcalendar挿入
    FULLCALENDAR(d.querySelectorAll('[data-plugin="calendar"]'),{
      googleCalendarApiKey: 'AIzaSyC9GBI2RVYzBruIMyQpygMkyWbhjpZANJM',
      contentHeight: 544,
      initialView: 'timeGridWeek',
      nowIndicator: true,
      header: {
        left: 'today prev,next title',
        center: '',
        right: ''
      },
      businessHours: {
        // days of week. an array of zero-based day of week integers (0=Sunday)
        daysOfWeek: [ 0, 1, 2, 3, 4, 5, 6 ], // Monday - Friday
        startTime: '08:00',
        endTime: '21:00',
      },
      columnHeaderHtml: function(date) {
        const weekday = ['月','火','水','木','金','土','日'];
        return `${date.getMonth()+1}/${date.getDate()}<br class="visible-xsmall">(${weekday[date.getUTCDay()]})`
      },
      eventSources: [
        {
          googleCalendarId: 'sunshine.tennis.plaza@gmail.com',
          className: 'events'
        },
        {
          googleCalendarId: 'japanese__ja@holiday.calendar.google.com',
          className: 'holiday'
        }
      ]
    });

    $('.p-pagetop__wrap .p-pagetop').on('click', function () {
      const $this = $(this).addClass('is-active');
      $('html,body').animate({scrollTop:0},500,()=>{
        setTimeout(() => $this.removeClass('is-active'), 1000);
      });
    });

  });

}