const [ w, d, mq ] = [ window, document, window.matchMedia('(max-width: 900px)') ];

const jqInit = () => {
  $.when( w.load['process'] ).then( () => {
    const $accTrigger = $('[data-plugin="accordion"]');
    $accTrigger.each(function() {
      const $self = $(this);
      $self.next().hide();
      $self.on('click', () => $self.toggleClass('is-active').next().slideToggle() );
    });
  });
}

export default function PROCESS () {
  $( jqInit );
}