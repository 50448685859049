import 'slick-carousel'

export default class SLIDER {

  constructor (opt={}) {
    this.obj = opt.obj || '[data-plugin="slider"]';
    this.animationDuration = opt.animationDuration || 8000;
    this.slickOpt = Object.assign({
      autoplay: false,
      fade: true,
      dots: false,
      speed: 2000,
      autoplaySpeed: 3000,
      pauseOnHover: false,
      pauseOnFocus: false,
      arrows: false
    }, opt.slickOpt );
  }

  init() {
    $(this.obj).slick( this.slickOpt )
    $(this.obj).on('beforeChange', (e, slick, cs, ns) => {
      $(`[data-slick-index=${ns}]`).addClass('is-animation-start')
      setTimeout(() => $(`[data-slick-index=${ns}]`).removeClass('is-animation-start'),
        this.animationDuration
      )
    })
  }

  start() {
    $('.mainvisual').addClass('is-slider-play')
    $(this.obj).slick('slickPlay').addClass('is-slickPlay')
    $(`[data-slick-index=0]`).addClass('is-animation-start')
    setTimeout(() => $(`[data-slick-index=0]`).removeClass('is-animation-start'),
      this.animationDuration
    )
  }
}