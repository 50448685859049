import SLIDER from "../modules/slider";
import GET_ARTICLES from '../plugins/getArticles';

const [ w, d, mq ] = [ window, document, window.matchMedia('(max-width: 900px)') ]

const slider = new SLIDER({
  slickOpt: {}
});

const jqReady = () => {
  
  // スライダー
  $.when( w.load['mainvisual'] ).then(() => slider.init() );

  $(w).on('load', () => {
    $.when( w.load['mainvisual'] ).then(() => slider.start() );
  });

  // news
  $.when(w.load['block-news']).then( () => {
    let makeItem = item => $(`
      <li class="block-news-item">
        <a href="${item.href}" class="block-news-item__link">
          <div class="block-news-item__body">
            <span class="date">${item.date}</span>
            <span class="cate">${item.category.name}</span>
            <span class="subject${item.is_new ? ' is-new': ''}">${item.title.str_truncate(35)}</span>
          </div>
        </a>
      </li>
    `)[0]
    GET_ARTICLES('.block-news__list[data-plugin="getArticles"]', { makeItem })
  })
}

export default function HOME() {
  $( jqReady )
}