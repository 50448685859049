/*-----------------------------------
 * MP hayabusa Customize
 * [このファイルの役割]
 * ・プラグインの読み込み
 * ・URLによるモジュールの実行
 * ・サイト設定
 * ・URLによるCSS読み込み設定
 *-----------------------------------*/

import 'jquery.easing'
import './plugins/plugins'
import './plugins/utility'
import loader from './plugins/loader'
import INIT from './modules/init'

// ページ読み込み
import HOME from './page/home'
import PROCESS from './page/process'
import FACILITY from './page/facility'

/*****
 * サイト設定
 */
window.siteinfo = {}

/*****
 * サイト初期化
 */
INIT()
loader(( request, path ) => {
  switch( path ){
    case '/': HOME(); break
    case '/process/': PROCESS(); break
    case '/facility/': FACILITY(); break
  }
  // switch( request ){}
})
