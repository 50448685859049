import { Calendar } from '@fullcalendar/core'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import googleCalendarPlugin from '@fullcalendar/google-calendar'
import fcLocale from '@fullcalendar/core/locales/ja'

export default function FULLCALENDAR(elm='',option={}){
  if(elm.length == 0) return false

  const defarr = [] // elmごとのpromise格納
  const elm_list = [].slice.call(elm) //nodelistの配列化

  elm_list.forEach(element => {
    const d = new $.Deferred()
    defarr.push(d.promise())

    const opt = $.extend(true,{
      googleCalendarApiKey:'',
      plugins: [ timeGridPlugin, dayGridPlugin, googleCalendarPlugin ],
      locale: fcLocale,
      initialView: 'dayGridMonth',
      fixedWeekCount: false,
      firstDay: 1,
      height: $.UA.is_mobile? 500: 620
    },option)

    let calendar = new Calendar(element, opt)
    $(element).empty()
    calendar.render()
    d.resolve(element)
  });


  return $.when.apply($,defarr)
}