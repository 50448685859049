const [ w, d, mq ] = [ window, document, window.matchMedia('(max-width: 900px)') ];

const jqInit = () => {
  $.when( w.load['header'] ).then( () => {
    $('header .p-gnav').currentMenu({mode:0,default:999});
  });
}

export default function FACILITY () {
  $( jqInit );
}